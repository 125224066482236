import { useEffect, useState } from 'react';
import './HeaderSlideMenu.css';
import { motion } from "framer-motion"
import mainApi from '../../../assets/api/MainApi';
import logo from '../../../assets/images/logo.png';
import star from '../../../assets/images/star-bg.svg';
import { Link } from 'react-router-dom';

function HeaderSlideMenu({ isOpen, setIsOpen }) {
    const [catalog, setCatalog] = useState([])

    function GetCategory() {
        // setIsPreloaderVisible(true);
        mainApi
            .getAllCategories()
            .then((res) => {
                setCatalog(res)
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setIsPreloaderVisible(false);
            });
    }

    useEffect(() => {
        GetCategory()
    }, [])

    return (
        <>
            <div onClick={() => setIsOpen(isOpen => !isOpen)} className={`header-menu__bg ${isOpen ? 'header-menu__bg_open' : ''}`}></div>
            {isOpen ?
                <motion.div
                    animate={{ opacity: 1, y: "9%" }}
                    initial={{ opacity: 0, y: "-100%" }}
                    transition={{ type: 'spring', stiffness: 40 }}
                    className="header-slide-menu">

                    <img src={star} alt='' className='loading__star-bg'></img>
                    <img src={logo} alt='' className='catalog__logo'></img>
                    <div className='catalog__title-menu__box'>
                        {catalog.map((item, i) => {
                            return (
                                <Link to={`/catalog/${item.id}`} onClick={() => setIsOpen(false)} key={i} className='catalog__title-menu'>{item.title}</Link>
                            )
                        })
                        }
                    </div>
                    <svg className='header-slide-menu__star' xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M13 0L15.9416 10.0584L26 13L15.9416 15.9416L13 26L10.0584 15.9416L0 13L10.0584 10.0584L13 0Z" fill="#FFB31F" />
                    </svg>
                </motion.div>
                : null}
        </>
    );
}

export default HeaderSlideMenu;