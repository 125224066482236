import ophisiant from '../images/ophisiant.svg'
import kalian from '../images/kalian-logo.svg'
import beelt from '../images/beelt-loggo.svg'

export const MAIN_URL = 'https://menu-union-99-strapi.polza-local.ru/api'
export const PHOTO_URL = 'https://menu-union-99-strapi.polza-local.ru/uploads'
export const MENU_URL = 'https://staff.union99.ru/api'

export const CATALOG = [
    {
        id: 1,
        title: 'Кальяны',
        translit_name: 'kalian'
    },
    {
        id: 2,
        title: 'Холодные напитки',
        translit_name: 'holnapitok'
    },
    {
        id: 3,
        title: 'Горячие напитки',
        translit_name: 'gornapitok'
    },
    {
        id: 4,
        title: 'Алкоголь',
        translit_name: 'alko'
    },
    {
        id: 5,
        title: 'Закуски',
        translit_name: 'zakuski'
    },
]

export const WAITER_CATALOG_BUTTON = {
    title: 'Бармен',
    icon: ophisiant,
    type: 'waiter'
};
export const MASTER_CATALOG_BUTTON = {
    title: 'Кальянный мастер',
    icon: kalian,
    type: 'master'
};
export const BILL_CATALOG_BUTTON = {
    title: 'Оплатить счет',
    icon: beelt,
    type: 'pay_bill'
};
export const CATALOG_BUTTON = [WAITER_CATALOG_BUTTON, MASTER_CATALOG_BUTTON, BILL_CATALOG_BUTTON];

export const HOOKAH_REQUEST_TYPE = 'hookah';
export const BARMAN_REQUEST_TYPE = 'barman';
export const CHECK_REQUEST_TYPE = 'check';

export const CATALOG_MAIN_LINK = 'catalog'
export const CATALOG_ITEM_LINK = 'item'
export const CART_MAIN_LINK = 'cart'

export const CURRENCY_MAP = {
    'USD': '$',  // United States Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'GBP': '£',  // British Pound
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF', // Swiss Franc
    'CNY': '¥',  // Chinese Yuan
    'SEK': 'kr',  // Swedish Krona
    'NZD': 'NZ$', // New Zealand Dollar
    'MXN': '$',  // Mexican Peso
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$', // Hong Kong Dollar
    'NOK': 'kr',  // Norwegian Krone
    'KRW': '₩',  // South Korean Won
    'TRY': '₺',  // Turkish Lira
    'INR': '₹',  // Indian Rupee
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'ZAR': 'R',  // South African Rand
};

export const productIdStorageKey = 'products_id'
export const historyIdStorageKey = 'history_cart'
export const tableStorageKey = 'table'

export const TABLES_BILL_LINK_MAP = {
    '1': 'https://eda.yandex.ru/inplace/menu?uuid=95b61255-5cc1-48fa-b8b6-270eec59f30d',
    '2': 'https://eda.yandex.ru/inplace/menu?uuid=8c990860-3d77-4592-ae60-468efaf69457',
    '3': 'https://eda.yandex.ru/inplace/menu?uuid=e13433a9-f83d-4bcd-a8d4-b646e1c1b75a',
    '4': 'https://eda.yandex.ru/inplace/menu?uuid=0dd9f4d1-986c-438a-8225-d1dacb77d2f0',
    '5': 'https://eda.yandex.ru/inplace/menu?uuid=bdd72158-b47d-42f1-9c66-9021385f7c96',
    '6': 'https://eda.yandex.ru/inplace/menu?uuid=f8f60a5b-98d6-4090-be86-95833de5927c',
    '7': 'https://eda.yandex.ru/inplace/menu?uuid=abd28392-3eeb-416c-af93-eafd00bc3c81',
    '8': 'https://eda.yandex.ru/inplace/menu?uuid=fba31503-e9cf-4dc6-9980-d3a56aa28163',
    '9': 'https://eda.yandex.ru/inplace/menu?uuid=4b561c32-d3ac-46cc-933b-c651bf9e31b2',
    '10': 'https://eda.yandex.ru/inplace/menu?uuid=f6bec43e-fdc2-4c67-be15-a4670dcbd6cf',
    '11': 'https://eda.yandex.ru/inplace/menu?uuid=3a31aadd-1b95-4479-b833-245b17e19b43',
    '12': 'https://eda.yandex.ru/inplace/menu?uuid=789835e7-68b4-4d46-ba7d-0b53c16a1dc7',
}