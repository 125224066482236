import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './SubcategoryPageTemplate.css';
import ServiceBtn from '../ServiceBtn/ServiceBtn';
import ServiceMenu from '../ServiceMenu/ServiceMenu';
import mainApi from '../../assets/api/MainApi';
import SubcategoryNav from './SubcategoryNav/SubcategoryNav';
import SubcategoryBlock from './SubcategoryBlock/SubcategoryBlock';
// import CartCounter from '../CartCounter/CartCounter';
import { CartContext } from '../../assets/contexts/cartContext';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import WaiterConf from '../WaiterConf/WaiterConf';

function SubcategotyPageTemplate() {
  const { id } = useParams();
  const container = useRef(null);
  const { cart } = useContext(CartContext);
  const [categories, setSCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentSubcatId, setCurrentSubcatId] = useState('');
  const [swiper, setSwiper] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConf, setIsOpenConf] = useState({
    visible: false,
    type: 'waiter',
    icon: ''
  });
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const subcategoryBlocks = document.querySelectorAll('.subcat-block');

  useEffect(() => {
    if (!id) return;

    setIsPreloaderVisible(true);
    mainApi
      .getAllProductByCategory({ categoryId: id })
      .then((res) => {
        setSCategories(res.categories);
        setProducts(res.items);
        setCurrentSubcatId(
          res.categories[0].title.toLowerCase().replaceAll(' ', '-')
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }, [id]);

  useEffect(() => {
    function handleAnchorClick(e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    }

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
      return () => anchor.removeEventListener('click', handleAnchorClick);
    });
  });

  useEffect(() => {
    if (subcategoryBlocks.length === 0) return;

    function trackScroll() {
      let scrollTop = window.scrollY;

      subcategoryBlocks.forEach((item, i) => {
        if (scrollTop > subcategoryBlocks[i].offsetTop - 325) {
          setCurrentSubcatId(subcategoryBlocks[i].id);
          swiper.slideTo(i, 500);
        }
      });
    }

    window.addEventListener('scroll', trackScroll);
    return () => window.removeEventListener('scroll', trackScroll);
  });

  function toggleServiceMenu() {
    setIsOpen((isOpen) => !isOpen);
  }

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = `unset`;
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  let realTableLocal = JSON.parse(localStorage.getItem('table'));

  return (
    <>
      <ServiceMenu isOpen={isOpen} setIsOpen={setIsOpen} onOpenConf={setIsOpenConf} />
      {realTableLocal?
        <ServiceBtn isOpen={isOpen} onOpen={toggleServiceMenu} onOpenConf={setIsOpenConf} />
        : null}
      {/* {realTableLocal ?
        <CartCounter />
        : null} */}
      <WaiterConf isOpen={isOpenConf} setIsOpen={setIsOpenConf} />

      <section
        className={`subcategory-page ${cart.length > 0 ? 'subcategory-page_with-btn' : ''
          }`}
      >
        {!isPreloaderVisible ? (
          products.length > 0 ? (
            <>
              <SubcategoryNav
                {...{ categories, products, setSwiper, currentSubcatId }}
              />

              <ul className="subcategory-page__products-box" ref={container}>
                {categories.map((item) => (
                  <SubcategoryBlock
                    category={item}
                    productsByCat={products.filter(
                      (product) => product.category.id === item.id
                    )}
                    key={item.title}
                  />
                ))}
              </ul>
              <p className='subcategory-page__promo'>Дизайн и Разработка - <a className='subcategory-page__promo-link' href='https://cabahub.com/' target='_blank' rel='noreferrer'>Caba Hub</a></p>
            </>
          ) : (
            <p className="subcategory-page__nothing-text">
              Здесь пока ещё ничего нет
            </p>
          )
        ) : (
          <div className="subcategory-page__preloader">
            <MiniPreloader />
          </div>
        )}
      </section>

      <div className="subcategory-page__blur" />
    </>
  );
}
export default SubcategotyPageTemplate;
