import './Catalog.css';
import logo from '../../assets/images/logo.png';
import line from '../../assets/images/logo-line.png';
import star from '../../assets/images/star-bg.svg';
import { motion } from "framer-motion"
import { Link, useNavigate, useParams } from 'react-router-dom';
import mainApi from '../../assets/api/MainApi';
import { useEffect, useState } from 'react';
import { CATALOG_BUTTON, TABLES_BILL_LINK_MAP } from '../../assets/utils/constants';
import { getRandomInt, getRandomNumber } from '../../assets/utils/utils';
import WaiterConf from '../WaiterConf/WaiterConf';
import Rules from '../Rules/Rules';

function Catalog({ catalog, onOpenConf }) {
    const [isRulesOpen, setRulesOpen] = useState(false);
    const [randomNumber, setRandomNumber] = useState(235);
    const [isOpenConf, setIsOpenConf] = useState({
        visible: false,
        type: '',
        icon: ''
    });

    useEffect(() => {
        const id = setInterval(() => {
            setRandomNumber(
                getRandomInt(50, 300)
            );
        }, 6000);

        return () => {
            clearInterval(id);
        };
    }, [randomNumber]);

    function OpenConfirm(item) {
        setIsOpenConf(prevState => ({
            ...prevState,
            visible: true,
            type: item.type,
            icon: item.icon
        }))
    }

    useEffect(() => {
        if (isOpenConf) {
            document.body.style.overflow = "hidden";
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
        }
    }, [isOpenConf]);

    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    return (
        <>
            <WaiterConf isOpen={isOpenConf} setIsOpen={setIsOpenConf} />
            <Rules isRulesOpen={isRulesOpen} setRulesOpen={setRulesOpen} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='catalog'>
                <p className='header-title'>СОЮЗ 99</p>
                <img src={star} alt='' className='loading__star-bg'></img>
                <motion.div
                    initial={{ opacity: 0.5, y: 0 }}
                    animate={{ opacity: 1, y: '-110vh' }}
                    transition={{ duration: 3.5, repeat: Infinity, repeatDelay: 3.0 }}
                    className='catalog__star_anim' style={{ left: randomNumber + 50, top: randomNumber }}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path d="M9.1315 9.1692L7.67581 15.0001L6.22015 9.1692L0.397461 7.71149L6.22015 6.25375L7.67581 0.422852L9.1315 6.25375L14.9542 7.71149L9.1315 9.1692Z" fill="#FFB31F" />
                    </svg>

                    <svg className='star-shleif' xmlns="http://www.w3.org/2000/svg" width="7" height="135" viewBox="0 0 7 135" fill="none">
                        <path d="M0 0H7L3.5 135L0 0Z" fill="url(#paint0_linear_1_28)" />
                        <defs>
                            <linearGradient id="paint0_linear_1_28" x1="3.5" y1="0" x2="3.5" y2="135" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFB31F" stopOpacity="0.27" />
                                <stop offset="1" stopColor="#1E1E2D" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>

                </motion.div>
                <img src={logo} alt='' className='catalog__logo'></img>
                <div className='catalog__title-menu__box'>
                    {catalog.map((item, i) => {
                        return (
                            <Link to={`/catalog/${item.id}`} key={item.id} className='catalog__title-menu'>{item.title}</Link>
                        )
                    })
                    }
                    <button onClick={() => setRulesOpen(true)} key={'rule'} className='catalog__title-menu'>Правила</button>
                </div>
                <a target="_blank" className='catalog__bonus-card' href='https://t.me/union99bot' rel='noreferrer'>
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1960_1228)">
                            <rect width="16" height="16" rx="8" fill="url(#paint0_linear_1960_1228)" />
                            <circle cx="8.00007" cy="8.00007" r="6.28571" stroke="#FFDB95" stroke-width="0.2" />
                            <circle cx="8" cy="8" r="7.6" stroke="#FFDB95" stroke-width="0.8" />
                            <g filter="url(#filter0_d_1960_1228)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.42847C7.55817 3.42847 7.2 3.78965 7.2 4.23519V5.84863C7.2 6.29418 7.55817 6.65536 8 6.65536C8.44183 6.65536 8.8 6.29418 8.8 5.84863V4.23519C8.8 3.78965 8.44183 3.42847 8 3.42847ZM5.96129 5.04191C6.05631 5.04191 6.13333 5.11959 6.13333 5.2154V8.53771C6.13333 9.57731 6.96907 10.4201 8 10.4201C9.03093 10.4201 9.86667 9.57731 9.86667 8.53771V5.2154C9.86667 5.11959 9.94369 5.04191 10.0387 5.04191H11.7591C11.8922 5.04191 12 5.15066 12 5.2848V8.53771C12 10.7654 10.2091 12.5713 8 12.5713C5.79086 12.5713 4 10.7654 4 8.53771V5.2848C4 5.15066 4.10784 5.04191 4.24086 5.04191H5.96129Z" fill="#FFDB95" />
                            </g>
                        </g>
                        <defs>
                            <filter id="filter0_d_1960_1228" x="4" y="3.42847" width="8" height="9.54282" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="0.4" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1960_1228" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1960_1228" result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_1960_1228" x1="2.57143" y1="15.1429" x2="12.5714" y2="1.42857" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#D6A65D" />
                                <stop offset="1" stop-color="#F8BB45" />
                            </linearGradient>
                            <clipPath id="clip0_1960_1228">
                                <rect width="16" height="16" rx="8" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    Игра с реальными бонусами <span className=''>→</span></a>
                {realTableLocal ?
                    <div className='catalog__block-button__box'>
                        {CATALOG_BUTTON.map((item, i) => {
                            return (
                                <>
                                    <div onClick={() => {
                                        if (item.type === 'pay_bill') {
                                            console.log({ realTableLocal })
                                            window.location.href = TABLES_BILL_LINK_MAP[realTableLocal]
                                            return
                                        }
                                        OpenConfirm(item)
                                    }} key={i} className='catalog__block-button'>
                                        <img alt='' src={item.icon}></img>
                                        <p className='catalog__block-button__title'>{item.title}</p>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                    : null}
                {/* <div className='loading__line__box'>
                    <img src={line} alt='' className='catalog__line'></img>
                    <div className='loading__blue'></div>
                    <div className='loading__terms'>
                        <p>Лаунж-бар СОЮЗ 99. © Все права защищены.</p>
                        <p>2024</p>
                    </div>
                </div> */}

                <div className='loading__line__box'>
                    <img src={line} alt='' className='loading__line'></img>
                    <div className='loading__blue'></div>
                    <div className='loading__terms'>
                        <p>Дизайн и Разработка - <a className='loading__terms-promo-link' href='https://cabahub.com/' target='_blank' rel='noreferrer'>Caba Hub</a> <br />Лаунж-бар СОЮЗ 99. © Все права защищены.</p>
                        <p>2024г</p>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Catalog