export const BellIcon = ({ mainClassName, strokeClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        className={strokeClassName}
        d="M16.6426 31.5V32.396C16.6426 34.8006 18.5919 36.75 20.9966 36.75C20.9977 36.75 20.9989 36.75 21.0001 36.75C23.4057 36.751 25.3566 34.8016 25.3576 32.396M25.3576 32.396C25.3576 32.3954 25.3576 32.3966 25.3576 32.396ZM25.3576 32.396V31.5"
        stroke="#1E1E2D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={strokeClassName}
        d="M31.4072 31.5C33.3915 31.5 35 29.8915 35 27.9072C34.9992 26.8993 34.599 25.9328 33.8871 25.2193L31.5 22.834V15.75C31.5 9.95101 26.799 5.25 21 5.25C15.201 5.25 10.5 9.95101 10.5 15.75V22.834L8.113 25.2193C7.40102 25.9327 7.00081 26.8993 7 27.9073C7 29.8915 8.60853 31.5 10.5927 31.5L31.4072 31.5Z"
        stroke="#1E1E2D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddIcon = ({
  mainClassName,
  fillClassName,
  strokeClassName,
  id,
}) => {
  return (
    <svg
      className={mainClassName}
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        className={fillClassName}
        width="28"
        height="28"
        rx="8"
        fill="#262637"
      />
      <path
        className={strokeClassName}
        d="M14 8V20"
        stroke="#FFB31F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className={strokeClassName}
        d="M8 14L20 14"
        stroke="#FFB31F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CheckIcon = ({
  mainClassName,
  fillClassName,
  strokeClassName,
  id,
}) => {
  return (
    <svg
      className={mainClassName}
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        className={fillClassName}
        width="28"
        height="28"
        rx="14"
        fill="#FFB31F"
      />
      <path
        className={strokeClassName}
        d="M19 10L12.9058 17.7983C12.8258 17.9008 12.6707 17.9008 12.5906 17.7983L9 13.2014"
        stroke="#1E1E2D"
        strokeWidth="2.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CloseIcon = ({ mainClassName, strokeClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        className={strokeClassName}
        d="M24 8L8 24"
        stroke="#CBCCD8"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        className={strokeClassName}
        d="M8 8L24 24"
        stroke="#CBCCD8"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TriangleIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillClassName}
        d="M7.88184 7.37305C7.88184 7.55534 7.81836 7.69857 7.69141 7.80273C7.56445 7.9069 7.39355 7.95898 7.17871 7.95898L0.811523 7.96387C0.599935 7.96387 0.430664 7.91178 0.303711 7.80762C0.176758 7.70345 0.113281 7.56022 0.113281 7.37793C0.113281 7.28027 0.131185 7.18913 0.166992 7.10449C0.206055 7.0166 0.25 6.92057 0.298828 6.81641L3.3457 0.546875C3.44661 0.335286 3.54753 0.19043 3.64844 0.112305C3.7526 0.0341797 3.86816 -0.00488281 3.99512 -0.00488281C4.12533 -0.00488281 4.24089 0.0341797 4.3418 0.112305C4.44271 0.19043 4.54525 0.335286 4.64941 0.546875L7.69141 6.81641C7.74349 6.91732 7.78743 7.01172 7.82324 7.09961C7.8623 7.18424 7.88184 7.27539 7.88184 7.37305Z"
        fill="#8A8AA3"
      />
    </svg>
  );
};
