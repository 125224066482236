import { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import './Header.css'

import { motion } from "framer-motion"
import HeaderSlideMenu from "./HeaderSlideMenu/HeaderSlideMenu";

function Header() {
    const { pathname } = useLocation();
    const [isOpenHeader, setIsOpenHeader] = useState(false);
    const [isHiddenAll, setIsHiddenAll] = useState(true);
    const [isHiddenPart, setIsHiddenPart] = useState(true)

    useEffect(() => {
        setIsHiddenAll(pathname === '/')
        setIsHiddenPart(pathname === '/catalog')
    }, [pathname])

    // useEffect(() => {
    //     if (!isOpenHeader) { document.body.style.overflow = 'initial'; }
    //     else { document.body.style.overflow = 'hidden'; }
    // }, [isOpenHeader]);
    const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
    const [popupScrollPosition, setPopupScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollWindowPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isOpenHeader) {
            document.body.style.overflow = "hidden";
            document.body.style.top = `-${scrollWindowPosition}px`;
            setPopupScrollPosition(scrollWindowPosition);
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
            console.log(popupScrollPosition);
            window.scrollTo(0, popupScrollPosition);
            setScrollWindowPosition(popupScrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenHeader]);

    const [realTable, setRealTable] = useState(null);
    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    useEffect(() => {
        // let realTableLocal = JSON.parse(localStorage.getItem('table'));
        if (!realTableLocal) {
            // localStorage.removeItem('table');
            setRealTable(null);
        } else {
            setRealTable((realTableLocal));
        }
    }, []);

    return (
        <>
            <div className={`header ${isHiddenAll ? 'header_hidden' : ''} ${isHiddenPart ? 'header_no-bg' : ''}`}>
                <div onClick={() => setIsOpenHeader(isOpenHeader => !isOpenHeader)} className={`header__burger-and-rocket-box ${isHiddenPart ? 'header_opacity' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                        <path d="M1.29415 12.305C0.71536 11.9048 0.71536 11.095 1.29415 10.6948C4.01441 8.81362 7.28122 7.79988 10.6351 7.79988L20.0598 7.79988C20.7505 7.79988 21.2601 8.33243 21.2601 8.92844L21.2601 14.0713C21.2601 14.6673 20.7505 15.1999 20.0598 15.1999L10.6351 15.1999C7.28121 15.1999 4.01441 14.1861 1.29415 12.305Z" stroke="#777786" strokeWidth="1.6" />
                        <path d="M11.0601 15.453L12.6804 18.7599C13.3521 20.1308 14.7457 20.9999 16.2723 20.9999L21 20.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M11.0601 6.99988L12.6804 3.693C13.3521 2.32203 14.7457 1.453 16.2723 1.453L21 1.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M18.3335 20.9999L18.3335 15.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M18.3335 1.453L18.3335 6.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M1.29415 12.305C0.71536 11.9048 0.71536 11.095 1.29415 10.6948C4.01441 8.81362 7.28122 7.79988 10.6351 7.79988L20.0598 7.79988C20.7505 7.79988 21.2601 8.33243 21.2601 8.92844L21.2601 14.0713C21.2601 14.6673 20.7505 15.1999 20.0598 15.1999L10.6351 15.1999C7.28121 15.1999 4.01441 14.1861 1.29415 12.305Z" stroke="#777786" strokeWidth="1.6" />
                        <path d="M11.0601 15.453L12.6804 18.7599C13.3521 20.1308 14.7457 20.9999 16.2723 20.9999L21 20.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M11.0601 6.99988L12.6804 3.693C13.3521 2.32203 14.7457 1.453 16.2723 1.453L21 1.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M18.3335 20.9999L18.3335 15.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M18.3335 1.453L18.3335 6.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M0.86936 3.64152C0.747323 3.58641 0.747323 3.41311 0.86936 3.35799L2.37719 2.67698C2.41171 2.66139 2.43935 2.63375 2.45493 2.59924L3.13594 1.09141C3.19106 0.96937 3.36436 0.96937 3.41948 1.09141L4.10049 2.59924C4.11607 2.63375 4.14371 2.66139 4.17822 2.67698L5.68606 3.35799C5.8081 3.41311 5.8081 3.58641 5.68606 3.64152L4.17822 4.32253C4.14371 4.33812 4.11607 4.36576 4.10049 4.40027L3.41948 5.90811C3.36436 6.03014 3.19106 6.03014 3.13594 5.90811L2.45493 4.40027C2.43935 4.36576 2.41171 4.33812 2.37719 4.32253L0.86936 3.64152Z" fill="#777786" />
                    </svg>
                    <div className="header__burger-box">
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? 45 : -180,
                                y: isOpenHeader ? '3px' : '-3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_first"></motion.div>
                        {isOpenHeader ? '' :
                            <div className="burger-line burger-line_second"></div>}
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? -45 : 180,
                                y: isOpenHeader ? '-3px' : '3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_third"></motion.div>
                    </div>
                </div>

                <Link onClick={() => setIsOpenHeader(false)} className='header__logo' to={`/catalog`}><p className='header-title'>СОЮЗ 99</p></Link>

                    <div
                        style={{ opacity: 0 }}
                    >
                        <svg className='header__cosmonavt' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path className='header__cosmonavt-stroke' d="M13.9999 21.5464C21.4842 21.5464 21.9881 17.8162 21.9881 12.5501C21.9881 7.53675 18.6022 4.00012 13.9999 4.00012C9.39762 4.00012 6.01172 7.53595 6.01172 12.5501C6.01172 17.8162 6.56093 21.5464 13.9999 21.5464Z" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                            <path className='header__cosmonavt-stroke' d="M6.0117 12.5511C6.0117 11.568 6.14216 10.6424 6.38669 9.78809H5.44217C4.64609 9.78809 4 11.164 4 12.8607C4 14.5574 4.64531 15.9334 5.44217 15.9334H6.17966C6.04217 14.9062 6.01248 13.7695 6.01248 12.5503L6.0117 12.5511Z" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                            <path className='header__cosmonavt-stroke' d="M22.5579 9.78809C21.7611 9.78809 21.6134 9.78809 21.6134 9.78809C21.8572 10.6416 21.9884 11.568 21.9884 12.5511C21.9884 13.7703 21.9611 14.907 21.8267 15.9342H22.5579C23.354 15.9342 24.0001 14.5582 24.0001 12.8615C24.0001 11.1648 23.3548 9.78809 22.5579 9.78809Z" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                            <path className='header__cosmonavt-stroke' d="M19.4701 15.0266C19.7436 14.2451 19.3478 13.3851 18.5862 13.1057C17.8246 12.8263 16.9855 13.2334 16.7121 14.0149C16.4387 14.7964 16.8344 15.6564 17.596 15.9358C18.3577 16.2152 19.1967 15.8081 19.4701 15.0266Z" fill="#FFB31F" />
                            <path className='header__cosmonavt-stroke' d="M18.4312 14.645L21.6467 15.8242" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                            <path className='header__cosmonavt-stroke' d="M18.5857 23.2518C17.4631 23.7366 15.974 23.9998 13.9998 23.9998C12.0248 23.9998 10.5358 23.7366 9.41394 23.2518" stroke="#FFB31F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            <path className='header__cosmonavt-stroke' d="M21.613 16.6665C19.8818 18.0048 17.1162 18.8712 13.9998 18.8712C10.8834 18.8712 8.11783 18.0048 6.3866 16.6665" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                            <path className='header__cosmonavt-stroke' d="M21.7225 10.2045C19.9999 8.81741 17.1827 7.91345 13.9999 7.91345C10.8171 7.91345 7.99998 8.81741 6.27734 10.2045" stroke="#FFB31F" strokeWidth="1.2" strokeMiterlimit="10" />
                        </svg>
                    </div>
            </div>
            <HeaderSlideMenu isOpen={isOpenHeader} setIsOpen={setIsOpenHeader} />
        </>
    )
}
export default Header