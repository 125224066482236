import './Rules.css';
import  cosmo from '../../assets/images/rules.png'
import { motion } from "framer-motion"
import circle from '../../assets/images/ellipse.png'
function Rules({ isRulesOpen, setRulesOpen }) {

    return (
        isRulesOpen ?
            <motion.div className='rules'
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ type: 'spring' }}
            >
                <svg onClick={() => setRulesOpen(false)} className='rules__close' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" >
                    <path d="M24 8L8 24" stroke="#CBCCD8" stroke-width="1.6" stroke-linecap="round" />
                    <path d="M8 8L24 24" stroke="#CBCCD8" stroke-width="1.6" stroke-linecap="round" />
                </svg>
                <img alt='' className='rules__circle' src={circle}></img>
                <svg className='rules__star' xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M13 0L15.9416 10.0584L26 13L15.9416 15.9416L13 26L10.0584 15.9416L0 13L10.0584 10.0584L13 0Z" fill="#D6A65D" />
                </svg>
                <div>
                    <img className='rules__cosmo-logo' alt='' src={cosmo}></img>
                    <p className='rules__title rules__title_big'>ПРАВИЛА</p>
                    <p className='rules__title rules__title_middle'>ЛАУНЖ-БАРА</p>

                    <div className='rules__text'>
                        {/* <div className='rules__text-block'> */}
                        <p className='rules__text-title rules__text-title_first'>Добро пожаловать в лаунж-бар<br></br>
                            «Союз-99» — лабораторию<br></br>
                            по изучению космических
                            технологий прошлого столетия.
                        </p>
                        <div className='rules__text-block_base'>
                            <p className='rules__text_preambula'>Мы относимся к вам с большим уважением, поэтому просим соблюдать ряд правил
                                при посещении.</p>
                            {/* </div> */}
                        </div>

                        <div className='rules__text-block rules__text-block_no-border  '>
                            <p className='rules__text-title'>18+</p>
                            <p className='rules__text_base'>
                                «Союз-99» — лаборатория<br></br>
                                для совершеннолетних. Администратор вправе попросить вас подтвердить возраст с помощью оригинала паспорта или водительского удостоверения. Ксерокопии, фотографии и скриншоты не являются документами.
                            </p>
                        </div>
                        <div className='rules__text-block  '>
                            <p className='rules__text-title'>
                                Hookah only
                            </p>
                            <p className='rules__text_base'>
                                В нашем заведении разрешено курить только кальян. Сигареты, сигары, IQOS, электронные сигареты, вейпы и т.д. — в других галактиках.
                            </p>
                        </div>
                        <div className='rules__text-block'>
                            <p className='rules__text-title  '>Ответственность</p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                Заведение не несёт ответственности
                                за оставленные, забытые, испорченные
                                или улетевшие за пределы стратосферы вещи.
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                Заведение не несёт ответственности
                                за нанесённый вам ущерб или вред третьими лицами.
                            </p>
                        </div>
                        <div className='rules__text-block'>
                            <p className='rules__text-title'>
                                No smoking
                            </p>
                            <p className='rules__text_base'>
                                Для гостей, которые хотят насладиться нашей барной и чайной картой, не заказывая кальян, предусмотрена система депозитов:
                            </p>
                            <div className='rules__comfort-rest'>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base'>1 - 2 человека</p>
                                    <p className='rules__comfort-rest__text_yellow'>2000₽</p>
                                </div>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base rules__comfort-rest__text_base'>Более 2 человек
                                        <span>Условия обсуждаются<br></br>
                                            с администрацией заведения.</span>
                                    </p>
                                    <p className='rules__comfort-rest__text_yellow'>Индивидуально</p>
                                </div>
                            </div>
                        </div>
                        <div className='rules__text-block  '>
                            <p className='rules__text-title'>Большая компания</p>
                            <div className='rules__comfort-rest'>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base'>1 - 3 человека</p>
                                    <p className='rules__comfort-rest__text_yellow'>1 кальян</p>
                                </div>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base'>4 - 6 человека</p>
                                    <p className='rules__comfort-rest__text_yellow'>2 кальяна</p>
                                </div>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base'>7 - 9 человека</p>
                                    <p className='rules__comfort-rest__text_yellow'>3 кальяна</p>
                                </div>
                                <div className='rules__comfort-rest__variant'>
                                    <p className='rules__text_base rules__comfort-rest__text_base'>Более 10 человек
                                        <span>Условия обсуждаются <br></br>
                                            с администрацией заведения.</span>
                                    </p>
                                    <p className='rules__comfort-rest__text_yellow'>Индивидуально</p>
                                </div>
                            </div>
                        </div>
                        <div className='rules__text-block  '>
                            <p className='rules__text-title'>90 МИНУТ</p>
                            <p className='rules__text_base'>
                                Время курения одного кальяна — 90 минут.<br></br>
                                По истечении этого времени кальян теряет основные курительные свойства, а вдыхаемый воздух становится перенасыщен углекислым газом. Это вредит вашему здоровью
                                и затрудняет путь в открытый космос.
                            </p>
                        </div>
                        <div className='rules__text-block'>
                            <p className='rules__text-title  '>В «Союзе-99» запрещено:</p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>
                                    Посещать заведение в состоянии сильного алкогольного или наркотического опьянения. <br></br>Мы уважаем комфорт и безопасность наших гостей, поэтому в случае несоблюдения данного правила будем вправе отказать вам в обслуживании.
                                </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>Приносить свои напитки. Администратор вправе изъять ваши напитки на время посещения заведения и вернуть их при вашем уходе. Исключение — напитки из магазина-партнёра «20/20» (после покупки сохраните чек и предъявите его нашему администратору). Также у нас есть космические чайная и барная карты — напитки из них точно придутся вам
                                    по вкусу.
                                </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>Излишне громко вести себя. Нам бы не хотелось, чтобы на шум и драки слетелись неприятели
                                    с соседних планет.
                                </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>Самостоятельно передвигать мебель.<br></br>
                                    Мы с удовольствием поможем вам объединить столы, если вы пришли большой компанией.
                                </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>Ради вашей безопасности и качества услуг мы просим не передвигать самостоятельно кальян, не трогать угли и не производить иные манипуляции с кальяном без кальянного мастера. Категорически запрещено добавлять
                                    в чашу наркотические вещества.
                                </p>
                            </p>
                        </div>


                        <div className='rules__text-block'>
                            <p className='rules__text-title rules__text_base_white  '>
                                Дополнительно:
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>Для вашего удобства рекомендуем бронировать стол заранее. При опоздании более чем на 15 минут без предупредительного звонка мы оставляем за собой право отменить вашу бронь.     </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'>—</span>
                                <p className='rules__text_base'>В заведении есть вип-лаборатория с PlayStation 5, вместимостью до 6 человек. Депозит —<br></br>
                                    <span className='rules__text_base_yellow'> 3500 ₽ в час.</span><br></br>
                                </p>
                            </p>
                            <p className='rules__text_base rules__text_base-box'>
                                <span className='rules__text__defis'> — </span>
                                Мы будем рады предоставить вам помещение для проведения мероприятия.<br></br>
                                Условия обсуждаются индивидуально.
                            </p>
                        </div>
                        <div className='rules__text-block  '>
                            <p className='rules__text_end'>
                                В случае нарушения правил заведения
                                вам может быть отказано в обслуживании.<br></br>
                                Также администрация вправе отказать<br></br>
                                в обслуживании без объяснения. Так мы стараемся обезопасить наших гостей
                                от нашествия враждебных инопланетян.
                            </p>
                        </div>
                        <svg className='rules__logo_end' xmlns="http://www.w3.org/2000/svg" width="42" height="85" viewBox="0 0 42 85" fill="none">
                            <path d="M18.8021 2.12695C19.8412 0.624349 21.9706 0.624349 23.0097 2.12695C26.8423 7.66916 28.9061 14.3222 28.9061 21.1504L28.9061 40.0002C28.9061 41.6921 27.5928 43.0008 26.0489 43.0008L15.7629 43.0008C14.2191 43.0008 12.9058 41.6921 12.9058 40.0002L12.9058 21.1504C12.9058 14.3222 14.9695 7.66916 18.8021 2.12695Z" stroke="#777786" stroke-width="2" />
                            <path d="M12.5725 22.0001L4.08091 26.3535C2.7456 27.0381 1.90576 28.4124 1.90576 29.913L1.90576 42.0002" stroke="#777786" stroke-width="2" stroke-linecap="round" />
                            <path d="M29.9061 22.1205L38.76 26.4587C40.131 27.1304 41 28.524 41 30.0507L41 42.0007" stroke="#777786" stroke-width="2" stroke-linecap="round" />
                            <path d="M1.90576 36.6678L11.9059 36.6678" stroke="#777786" stroke-width="2" stroke-linecap="square" />
                            <path d="M41 36.6678L30.9998 36.6678" stroke="#777786" stroke-width="2" stroke-linecap="square" />
                            <path d="M21.7071 51.2939C21.3166 50.9034 20.6834 50.9034 20.2929 51.2939L13.9289 57.6579C13.5384 58.0484 13.5384 58.6816 13.9289 59.0721C14.3195 59.4626 14.9526 59.4626 15.3431 59.0721L21 53.4153L26.6569 59.0721C27.0474 59.4626 27.6805 59.4626 28.0711 59.0721C28.4616 58.6816 28.4616 58.0484 28.0711 57.6579L21.7071 51.2939ZM20 84.0016C20 84.5539 20.4477 85.0016 21 85.0016C21.5523 85.0016 22 84.5539 22 84.0016L20 84.0016ZM20 52.001L20 84.0016L22 84.0016L22 52.001L20 52.001Z" fill="#FFB31F" />
                            <path d="M9 65L9 80" stroke="#FFB31F" stroke-width="2" stroke-linecap="round" />
                            <path d="M33 65L33 80" stroke="#FFB31F" stroke-width="2" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
                <div className="subcategory-page__blur"></div>
            </motion.div >
            : null
    );
}
export default Rules;