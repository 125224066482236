import { useEffect, useState } from 'react';
import './Loading.css';
import logo from '../../assets/images/logo.png';
import line from '../../assets/images/logo-line.png';
import star from '../../assets/images/star-bg.svg'
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"

function Loading() {
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        const totalDuration = 2000; // 5 seconds
        const step = 0.1;
        const intervalDuration = 6; // 50 milliseconds
        const stepsInInterval = (step / 100) * totalDuration / intervalDuration;
        const id = setInterval(() => {
            setProgress(prevProgress => {
                const newProgress = prevProgress + stepsInInterval;
                if (newProgress >= 100) {
                    clearInterval(id);
                    return 100;
                }
                return newProgress;
            });
        }, intervalDuration);

        setTimeout(() => {
            navigate("/catalog")
        }, totalDuration);

        return () => {
            clearInterval(id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div
            animate={{ opacity: 0 }}
            initial={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.8 }}
            className='loading'>
            <img src={star} alt='' className='loading__star-bg'></img>
            <img src={logo} alt='' className='loading__logo'></img>
            <p className='loading__title'>MENU</p>
            <div className='loading__progress-box'>
                <div
                    style={{
                        width: `${progress}%`,
                    }}
                    className='loading__progress'
                ></div>
            </div>
            {/* <p className='loading__perent'>{`${Math.round(progress)}%`}</p> */}
            <div className='loading__line__box'>
                <img src={line} alt='' className='loading__line'></img>
                <div className='loading__blue'></div>
                <div className='loading__terms'>
                    <p>Дизайн и Разработка - <a className='loading__terms-promo-link' href='https://cabahub.com/' target='_blank' rel='noreferrer'>Caba Hub</a> <br />Лаунж-бар СОЮЗ 99. © Все права защищены.</p>
                    <p>2024г</p>
                </div>
            </div>
        </motion.div>
    );
}

export default Loading